<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data:{}
			}
		},
		computed:{
			isLogin(){
				return this.user && Gen.userToken() ? true : false
			},
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: this.web.lbl_subscription,
					link: { name: 'Subscribe' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});

			this.getApi()
		},
		methods: {
			getApi() {
				if(this.$root.subscribeActive != 'true') return this.$router.push({name:'index'})
				Gen.apirest("/list-subscribe", {}, (err,resp)=>{
					this.$root.topProgress.done()
					if(err) console.log(err)
					this.data = resp.data
				})
			},
			countSubscribe(id) {
				Gen.apirest("/hit-count-subscribe", {id:id}, (err,resp)=>{
					if(err) console.log(err)
					console.log("success")
				})
			}
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
			<section class="section notopmargin nobottommargin wall-bg">
				<div class="container">
					<BreadCrumb :data="breadCrumb"></BreadCrumb>
					<div class="row">
						<div class="col-md-6" v-for="(v,k) in data" :key="k">
							<div class="row justify-content-center">
								<div class="col-md-8">
									<div class="subscribe-part more-package">
										<img :src="uploader(v.image)" :alt="v.title">
									</div>
								</div>
							</div>
							<div class="subscribe-part more-package mb-5">
								<div class="heading-title">
									<h1>{{v.title}}</h1>
									<p v-html="v.description"></p>
									<div class="text-center">
										<router-link v-if="!isLogin" :to="{name:'Login'}" class="button button-red">{{web.btn_subscribe_now}}</router-link>
										<a v-else @click="countSubscribe(v.id)" :href="'https://wa.me/62'+v.message.phone+'?text='+v.message.message+''" target="_blank" class="button button-red">{{web.btn_subscribe_now}}</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
</template>